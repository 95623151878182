import React from 'react';
import Col from '../../../grid/col';
import i18n from '../../../../libs/i18n/i18n';
import Row from '../../../grid/row';
var ActivityComment = function (_a) {
    var comment = _a.comment, setComment = _a.setComment;
    return (React.createElement(Row, { className: "rform__row" },
        React.createElement(Col, { sm: 3 },
            i18n.t('tagActivity.comment.label'),
            ":"),
        React.createElement(Col, { sm: 7, column: true },
            React.createElement("div", { className: "rdropdown" },
                React.createElement("div", { className: "rdropdown__input-container" },
                    React.createElement("input", { type: "text", onChange: function (e) { return setComment(e.target.value); }, maxLength: 1000 }))))));
};
export default ActivityComment;
